import * as Styled from './styled';

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  gap?: number;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  theme?: any;
  style?: React.CSSProperties;
}

const Stack: React.FC<StackProps> = ({
  children,
  gap = 50,
  style,
  alignItems,
  justifyContent,
  theme = {},
  ...rest
}) => {
  return (
    <Styled.Stack {...rest} style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}>
      {children}
    </Styled.Stack>
  );
};

const Centered: React.FC<StackProps> = ({
  children,
  gap = 50,
  theme = {},
  style,
  alignItems,
  justifyContent,
  ...rest
}) => {
  return (
    <Styled.CenteredStack
      {...rest}
      style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}
    >
      {children}
    </Styled.CenteredStack>
  );
};

// export default PopoverMenu;
let ComposedStack = Object.assign(Stack, {
  Centered: Centered,
});

export default ComposedStack;
